<template>

  <div>

    <contact-filters
      :tag-filter.sync="tagFilter"
      :tag-options="tagOptions"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索"
                v-b-tooltip.hover.bottom="`联系人备注 微信名 公司 电话 描述`"
              />
             
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        v-if="axiosStatus"
        ref="refUserListTable"
        class="position-relative"
        :items="filterContacts"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="fields"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template #cell(contact)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.external_contact.avatar"
              />
            </template>
            <p              
              class="font-weight-bold d-block text-nowrap mb-0">
              {{ data.item.follow_info.remark }}
            </p>
            <small class="text-muted">@{{ data.item.external_contact.name }}</small>
          </b-media>
        </template>    

        <template #cell(company)="data">
          <div class="text-nowrap">
          <b-badge 

          v-if="data.item.external_contact.corp_full_name"
            variant="light-warning">
            <font-awesome-icon
                icon="fa-brands fa-weixin"
                size="lg"
                class="mr-75"/>
            {{ data.item.external_contact.corp_full_name }}
          </b-badge>


          <p>{{ data.item.follow_info.remark_corp_name}}</p>
        </div>
        </template>  

        <template #cell(phone)="data">
          <div class="text-nowrap">
          <p
           v-if="data.item.follow_info.remark_mobiles.length !== 0"
           v-for="phone in data.item.follow_info.remark_mobiles">
          
            {{ phone}}
          </p>
        </div>
        </template>  

        <template #cell(description)="data">
          <div class="text-nowrap">
          <p>{{ data.item.follow_info.description}}</p>
        </div>
        </template>  

        <template #cell(tag)="data">
          <b-badge 
          pill 
          variant="light-primary"
          class="mr-1"
          v-if="data.item.follow_info.tag_id"
          v-for="i in data.item.follow_info.tag_id"
          >
          {{ tagNameMapping(i) }}
        </b-badge>
        </template>  

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
            @click="checkUser(data.item.external_contact.external_userid)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">查看详情</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>  
      </b-table>

      <div 
      v-else
      class="text-center">
        <b-spinner
          variant="primary"
          class="mr-1"
          type="grow"
        />
      </div>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共 {{ totalContacts }} 条数据）</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ContactFilters from './ContactFilters.vue'

export default {
  components: {
    ContactFilters,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },      
  directives:{
      'b-tooltip': VBTooltip,
  },
  methods: {
    checkTagUnion:function(tagsA,tagsB){
      if (tagsA.length == tagsB.length) {
        for (var i = 0; i < tagsA.length; i++) {
          if (tagsA[i] != tagsB[i]) {
            return false;
          }
        }
        return true;
      }
    },
    getContacts:function(){
      this.$axios.get('/third_party/wechat/get_contacts').then((response) => {
      response.data.data.contacts.forEach(i => {
        this.contacts.push(i)
      });
      response.data.data.tags.forEach(tagGroup => {
        this.tags.push(tagGroup)
        tagGroup.tag.forEach(tag => {
          this.tagOptions.push({label:tag.name,value:tag.id})
        });
      });
      this.axiosStatus = true
      this.searchQuery = ""
    });
    },
    tagNameMapping: function (tag_id) {
      var tag_name = ''
      this.tags.forEach(groups => {
          groups.tag.forEach(tag => {
          if (tag.id == tag_id) {
            tag_name = tag.name
            return
          }
        });
      });
      return tag_name
    },
    checkUser(id){
      this.$axios.get("/crm/contacts/wechat/get_bind_contact/" + id).then(resp => {
        this.$router.push({ name: 'crm-contacts-view', params: { id: resp.data.data.contact_id } })
      })
    },
  },
  computed:{
    dataMeta(){
      return {
        from: (this.currentPage - 1) * this.perPage + 1,
        to: Math.min(this.currentPage * this.perPage, this.totalContacts),
      }
    },
    filterContacts(){
      return this.contacts.filter((i) => {
          return (
            i.follow_info.remark.includes(this.searchQuery) 
          || i.external_contact.name.includes(this.searchQuery)
          || i.external_contact.corp_full_name.includes(this.searchQuery)
          || i.follow_info.remark_corp_name.includes(this.searchQuery)
          || JSON.stringify(i.follow_info.remark_mobiles).includes(this.searchQuery)
          || i.follow_info.description.includes(this.searchQuery)
          )
          &&( this.tagFilter.length > 0 ? this.checkTagUnion(i.follow_info.tag_id,this.tagFilter) : true)
        })
    },
    totalContacts : function(){
      return this.filterContacts.length
    },
  },
  data(){
    return{
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      axiosStatus: false,
      isSortDirDesc: true,
      searchQuery: " ",
      contacts: [],
      tags: [],
      tagOptions:[],
      tagFilter:[],
      perPage: 25,
      currentPage: 1,
      fields: [
        {
          key: 'contact',
          label: '联系人',
        },
        {
          key: 'company',
          label: '公司',
        },
        {
          key: 'phone',
          label: '电话',
        },
        {
          key: 'description',
          label: '描述',
        },
        {
          key: 'tag',
          label: '标签',
        },
        {
          key: 'actions',
          label: '操作',
        }
      ],
    }
  },  
  created(){
   this.getContacts()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
